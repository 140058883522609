import React from 'react';
// import { Link } from 'gatsby'

import Layout from '../components/Layout';
import H1 from '../components/H1';

const Offer = () => (
  <Layout>
    <H1>Oferta BONIKA</H1>
    <p>
      Szanowni Państwo poniższa lista stanowi jedynie część naszej oferty, w
      związku z czym zachęcamy do kontaktu. Wszystkie ceny są cenami netto do
      których należy doliczyć koszt przesyłki.
    </p>
    <p>
      Nasze produkty znalazły niezwykłe uznanie u naszych zachodnich sąsiadów,
      mieszkańcy Niemiec są głównym odbiorcą naszych produktów, które cieszą się
      wysokim uznaniem i popularnością. Postanowiliśmy rozszerzyć naszą ofertę
      również w kraju.
    </p>
    <p>
      Kupując u nas otrzymujecie Państwo produkty najwyższej jakości{' '}
      <strong>ŻYCZYMY SMACZNEGO</strong>
    </p>

    <table>
      <thead>
        <tr>
          <th>Produkt</th>
          <th>Waga</th>
          <th>Cena</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Mikroklimat Ula</td>
          <td>&nbsp;</td>
          <td>do uzgodnienia</td>
        </tr>
        <tr>
          <td>Miód Wielokwiatowy</td>
          <td>1200g</td>
          <td>40,00zł</td>
        </tr>
        <tr>
          <td>Miód Nawłociowy</td>
          <td>1200g</td>
          <td>40,00zł</td>
        </tr>
        <tr>
          <td>Miód Lipowy</td>
          <td>1200g</td>
          <td>45,00zł</td>
        </tr>
        <tr>
          <td>Miód Gryczany</td>
          <td>1200g</td>
          <td>45,00zł</td>
        </tr>
        <tr>
          <td>Miód Mniszkowy</td>
          <td>1200g</td>
          <td>45,00zł</td>
        </tr>
        <tr>
          <td>Miód Spadziowy</td>
          <td>1200g</td>
          <td>60,00zł</td>
        </tr>
        <tr>
          <td>Miód Rzepakowy</td>
          <td>1200g</td>
          <td>40,00zł</td>
        </tr>
        <tr>
          <td>Pierzga w miodzie</td>
          <td>100g</td>
          <td>40,00zł</td>
        </tr>
        <tr>
          <td>Propolis (kit pszczeli)</td>
          <td>50g</td>
          <td>20,00zł</td>
        </tr>
        <tr>
          <td>Wosk Pszczeli</td>
          <td>1000g</td>
          <td>40,00zł</td>
        </tr>
      </tbody>
    </table>
  </Layout>
);

export default Offer;
